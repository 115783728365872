import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Intro from "../components/Intro"
import Activities from "../components/Activities"

import { injectIntl } from "gatsby-plugin-intl"

const AboutPage = ({ intl }) => {
  console.log("intl:", intl)
  const rawData = useStaticQuery(graphql`
    {
      nl: markdownRemark(
        frontmatter: { id: { eq: "about" }, langKey: { eq: "nl" } }
      ) {
        html
        ...SEO
        frontmatter {
          title
        }
      }

      fr: markdownRemark(
        frontmatter: { id: { eq: "about" }, langKey: { eq: "fr" } }
      ) {
        html
        ...SEO
        frontmatter {
          title
        }
      }
    }
  `)

  const data = rawData[intl.locale]

  const {
    frontmatter: { title, ogImage },
    html,
  } = data

  return (
    <>
      <SEO lang={intl.locale} title={title} ogImage={ogImage} />
      <Layout>
        <div className="c-page-wrapper c-page-wrapper--trailer">
          <Intro html={html} title={title} />
          <Activities />
        </div>
      </Layout>
    </>
  )
}

export default injectIntl(AboutPage)
